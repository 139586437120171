/**
 * Main TS
 */
import { setIsMobile } from '../../utilities/IsMobile.js';

/**
 * Initialize Main
 */
export default function init() {
  setIsMobile(767);
}
